import React from 'react';
import CampoDeSelecaoAssincrono from '../CampoDeSelecaoAssincrono';
import MensagemDeErro from '../MensagemDeErro';
//@ts-ignore
import Mensagem from '@bit/digix.digixui.mensagem';
//@ts-ignore
import { Cartao } from '@bit/digix.digixui.cartao';
//@ts-ignore
import { BotaoComTextoEIcone } from '@bit/digix.digixui.botao';
import CampoCheckbox from '../CampoCheckbox';

interface IEventosDoencaCronica {
  buscarCid: (cid: string) => void,
  atualizarDoencaCronica: (tipoDeDoenca: string, doencaCronica: number, nomeDaDoencaCronica: string) => void,
  removerDoencaCronica: (tipoDeDoenca: string) => void,
}

interface ICampoDeSelecaoAssincronoProps {
  value: number,
  label: string,
}

interface ICampoDoencaCronicaProps {
  possuiDoencaCronica: boolean,
  doencaCronica: string,
  nomeDaDoencaCronica: string,
  erroDoencaCronica?: boolean,
  eventosDoencaCronica: IEventosDoencaCronica,
  onChangePossuiDoencaCronica: (event: React.ChangeEvent<HTMLInputElement>) => void,
  afirmacaoPossuiDoencaCronica: string,
  perguntaPossuiDoencaCronica: string,
  disabled: boolean,
  tituloTipoDeDoenca: string,
  tipoDeDoenca: string
}

const CampoDoencaCronica = ({ possuiDoencaCronica, doencaCronica, nomeDaDoencaCronica,
  erroDoencaCronica, eventosDoencaCronica, afirmacaoPossuiDoencaCronica, perguntaPossuiDoencaCronica,
  onChangePossuiDoencaCronica, disabled, tituloTipoDeDoenca, tipoDeDoenca }: ICampoDoencaCronicaProps) => {

  return (
    <div className="grade__coluna">
      <h2 className="titulo titulo_pequeno texto_cor-secundaria">
        <strong>{tituloTipoDeDoenca}</strong>
      </h2>
      <div className='grade__linha'>
        <div className='grade__coluna'>
          <div className="formulario__label">
            {perguntaPossuiDoencaCronica}
          </div>
          <CampoCheckbox
            name={'possuiDoencaCronica' + tipoDeDoenca}
            label={afirmacaoPossuiDoencaCronica}
            checked={possuiDoencaCronica}
            //@ts-ignore
            onChange={onChangePossuiDoencaCronica}
            disabled={disabled}
          />
          {possuiDoencaCronica &&
            <>
              <Mensagem
                tipo='atencao'
                icone='far fa-exclamation-circle'>
                Caso esta informação <strong>não seja comprovada</strong> no futuro, a inscrição pode ser <strong>desclassificada de processos de seleção</strong>.
              </Mensagem>
              <div className={`formulario__input-react-select u-margem-superior-media ${erroDoencaCronica && 'formulario__input-react-select_erro'}`}>
                <label
                  className="formulario__label">
                  Informe a CID (Classificação Internacional de Doenças) da doença crônica
                </label>
                <p className="formulario__descricao">A CID pode ser encontrado no atestado médico emitido pelo profissional responsável, que comprovará a deficiência de acordo com as definições do Decreto nº 3.298/99 (artigos 3º e 4º) e com as alterações dadas pelo Decreto nº 5.296/2004.</p>
                {erroDoencaCronica && <MensagemDeErro campo={'doencaCronica' + tipoDeDoenca} />}
                <CampoDeSelecaoAssincrono
                  id={'doencaCronica' + tipoDeDoenca}
                  name={'doencaCronica' + tipoDeDoenca}
                  value={doencaCronica}
                  placeholder="Selecione a CID"
                  textoDeBusca="Selecione a CID"
                  onChange={(e: ICampoDeSelecaoAssincronoProps) => eventosDoencaCronica.atualizarDoencaCronica(tipoDeDoenca, e.value, e.label)}
                  disabled={!!doencaCronica || disabled}
                  obterOpcoes={eventosDoencaCronica.buscarCid}
                />
              </div>
              {doencaCronica &&
                <Cartao
                  titulo={nomeDaDoencaCronica}
                  cor="cinza"
                  className="u-margem-superior-pequena"
                  containerSecundario={!disabled ? (
                    <BotaoComTextoEIcone
                      tipo="button"
                      cor="perigo"
                      visual="contorno"
                      tamanho="pequeno"
                      icone="far fa-trash"
                      posicaoDoIcone="direita"
                      disabled={disabled}
                      onClick={() => eventosDoencaCronica.removerDoencaCronica(tipoDeDoenca)}>
                      Remover
                    </BotaoComTextoEIcone>) : (<></>)} />
              }
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default CampoDoencaCronica;