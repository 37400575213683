import { apiDaInscricao } from './axios';
import IParametrosPesquisaDeInscricao from '../interfaces/IParametrosDePesquisaDeInscricao';
import { removerAcentosECaracteresEspeciais } from './../helpers/strings';
import { manterSomenteNumeros } from '../helpers/mascaras';

const consultarExistenciaDeInscricao = async (cpf: string) => {
  const resposta = await apiDaInscricao().post(`/inscricao/${cpf}/possuiInscricao`);
  return resposta.data;
};

const validarPermissaoParaEdicao = async (idDaInscricao: Number) => {
  const resposta = await apiDaInscricao().get(`/inscricao/${idDaInscricao}/validarPermissaoParaEdicao`);
  return resposta.data.permitidoEdicao;
};

const consultarInscricoes = async ({ cpf, nome, numero, paginaAtual }: IParametrosPesquisaDeInscricao) => {
  const cpfSemMascara = manterSomenteNumeros(cpf);
  const nomeSemAcentosECaracteresEspeciais = removerAcentosECaracteresEspeciais(nome);
  const resposta = await apiDaInscricao().get(`/inscricao/obter?cpf=${cpfSemMascara}&nome=${nomeSemAcentosECaracteresEspeciais}&numero=${numero}&pagina=${paginaAtual}`);

  return resposta;
};

export { consultarExistenciaDeInscricao, validarPermissaoParaEdicao, consultarInscricoes };