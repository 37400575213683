import React from 'react';
import grausDeParentesco from '../../componentes/Formulario/grausDeParentesco';
import { Link } from 'react-router-dom';
import IDependente from '../../../../interfaces/IDependente';

interface ItemDependenteProps {
    dependente: IDependente,
    removerDependenteDaListagem: (id: number, nome: string) => void,
    disabled: boolean
}

interface IGrauDeParentesco {
    value: string,
    label: string
}

const ItemDependente = ({ dependente, removerDependenteDaListagem, disabled }: ItemDependenteProps) => {

  const { id, nome, grauDeParentesco, dataDeNascimento, deficiencias, cidDaDoencaCronica, cidDaDoencaCronicaDegenerativa, cidDaDoencaCronicaCancer } = dependente;
  const possuiDoencaCronica = !!cidDaDoencaCronica;
  const deveExibirDeficiencia = !!deficiencias && deficiencias.length > 0;
  const deveExibirDoencaCronica = possuiDoencaCronica;
  const deveExibirTagsDeDeficiencia = deveExibirDeficiencia || deveExibirDoencaCronica;
  const deveExibirDoencaCronicaDegenerativa = !!cidDaDoencaCronicaDegenerativa;
  const deveExibirDoencaCronicaCancer = !!cidDaDoencaCronicaCancer;

  return (
    <div className="lista-de-cadastro__item" data-test-id="item-dependente">
      <div className='grade'>

        <div className='grade__linha grade__linha_alinhar-vertical-centro grade__linha_distribuir-horizontal-entre-elementos'>

          <div className='grade_coluna grade_coluna_10'>
            <div className="lista-de-cadastro__info">
              <div className="lista-de-cadastro__icone">
                <i className="fal fa-user-alt" aria-hidden="true"></i>
              </div>

              <div className="lista-de-cadastro__dados">
                <h3 className="lista-de-cadastro__titulo" data-test-id="nome-dependente">{nome}</h3>
                <p className="lista-de-cadastro__subtitulo" data-test-id="parentesco-nascimento-dependente">
                  {grauDeParentesco && grausDeParentesco.filter((x: IGrauDeParentesco) => x.value == grauDeParentesco)[0].label} 
                  {(grauDeParentesco && dataDeNascimento) && ' - '} 
                  {dataDeNascimento && (<>Nascido em <strong>{dataDeNascimento?.toString()}</strong></>)}
                </p>
              </div>
            </div>

            {deveExibirTagsDeDeficiencia &&
            <div className="lista-de-cadastro__tags">
              {deveExibirDeficiencia && <p className="lista-de-cadastro__tag" data-test-id="portador-deficiencia">Portador de deficiência</p>}
              {deveExibirDoencaCronica && <p className="lista-de-cadastro__tag" data-test-id="portador-doenca-cronica">Tem doença crônica incapacitante para o trabalho</p>}
              {deveExibirDoencaCronicaDegenerativa && <p className="lista-de-cadastro__tag" data-test-id="portador-doenca-cronica">Tem doença crônica e degenerativa</p>}
              {deveExibirDoencaCronicaCancer && <p className="lista-de-cadastro__tag" data-test-id="portador-doenca-cronica">Tem Câncer</p>}

            </div>}
          </div>
          <div className="grade_coluna grade_coluna_2">

            <div className="lista-de-cadastro__acoes">
              {!disabled && 
              <>
                <button
                  className="botao botao_pequeno botao_contorno botao_largura-total botao_erro botao_com-icone-para-esquerda"
                  onClick={() => removerDependenteDaListagem(id, nome)} data-test-id="excluir-dependente">
                  <i className="fal fa-trash-alt" aria-hidden="true"></i>Excluir
                </button>
                <Link
                  to={`/editar-dependente/${id}`}
                  className="botao botao_pequeno botao_largura-total botao_contorno botao_com-icone-para-esquerda" data-test-id="alterar-dependente">
                  <i className="fal fa-edit" aria-hidden="true"></i>Alterar
                </Link>
              </>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDependente;