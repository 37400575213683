import React from 'react';
import { CampoDeTexto, IndicadorPasso } from '../../..';
import MensagemDeErro from '../../../MensagemDeErro';
import IContato from '../../../../interfaces/IContato';
import { IPretendenteValidacaoErroCampos } from '../../../../interfaces/IPretendenteValidacao';
import CampoDeTextoComMascara from '../../../CampoDeTextoComMascara';
import IPretendente from '../../../../interfaces/IPretendente';
import mascaras from '../../../../helpers/mascaras';

interface IInputEventsProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface ICampoContatoProps {
  dadosDoPretendente: IPretendente,
  erros?: IPretendenteValidacaoErroCampos,
  inputEvents: IInputEventsProps,
  disabled: boolean,
  onChangeContato: (contato: IContato) => void,
  mostrarIndicadorDePasso?: boolean,
  mostrarDadosDoDonoDoContato?: boolean
}

const CampoContato = ({ dadosDoPretendente, erros, inputEvents, disabled, onChangeContato, mostrarIndicadorDePasso = false }: ICampoContatoProps) => {
  const mascaraDeCelular = '(99) 99999-9999';
  const mascaraDeTelefoneFixo = '(99) 9999-9999';
  const mascaraDeTelefoneFixoParaRecado = mascaraDeTelefoneFixo + '9';
  const tamanhoDoTelefoneCelularComMascara = 15;
  const mascaraDeTelefoneParaRecado = dadosDoPretendente?.contato?.telefoneParaRecado?.length < tamanhoDoTelefoneCelularComMascara ? mascaraDeTelefoneFixoParaRecado : mascaraDeCelular;
  const textoExplicativo = 'Altere os dados de contato do titular. Ele poderá recuperar a senha através dessas informações.';
  
  return (
    <>
      <div className='grade__coluna'>
        {mostrarIndicadorDePasso && 
        <>
          <IndicadorPasso
            iconeDoPasso='fa-envelope'
            titulo='Informações de contato'
            descricao={textoExplicativo} />
          <div className='grade__linha grade__linha_alinhar-vertical-a-base'>
            <div className='grade__coluna grade__coluna_6'>
              <label className='formulario__label'>
                Nome completo
              </label>
              <label className='formulario__descricao'>
                {dadosDoPretendente.nome}
              </label>
            </div>
            <div className='grade__coluna grade__coluna_6'>
              <label className='formulario__label'>
                CPF
              </label>
              <label className='formulario__descricao'>
                {mascaras.aplicarMascaraDeCpf(dadosDoPretendente.cpf)}
              </label>
            </div>
          </div>
        </>
        }
        {!mostrarIndicadorDePasso && 
        <>
          <h2 className='titulo titulo_medio texto_cor-secundaria'>
            <strong>Informações de contato</strong>
          </h2>
          <hr />
          <p className='formulario__descricao'>
            Informe pelo menos um número de telefone.
          </p>
        </> 
        }
        {erros?.telefone && <MensagemDeErro mensagem={erros?.telefone} />}
      </div>
      <div className='grade__linha grade__linha_alinhar-vertical-a-base'>
        <CampoDeTexto
          colunas='12'
          label='E-mail'
          name='email'
          value={dadosDoPretendente?.contato?.email || ''}
          erro={erros?.email}
          tipo='email'
          required={false}
          disabled={disabled}
          {...inputEvents}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeContato({...dadosDoPretendente.contato, email: event.target.value})}
        />
      </div>
      <div className='grade__linha grade__linha_alinhar-vertical-a-base'>
        <CampoDeTextoComMascara
          colunas='6'
          label='Telefone celular'
          name='telefoneCelular'
          mascara={mascaraDeCelular}
          tipo='tel'
          value={dadosDoPretendente?.contato?.telefoneCelular || ''}
          disabled={disabled}
          {...inputEvents}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeContato({...dadosDoPretendente.contato, telefoneCelular: event.target.value})}>
          <div>Este número de telefone será utilizado para recuperação da senha de acesso à sua inscrição.</div>
        </CampoDeTextoComMascara>
      </div>
      <div className='grade__linha'>
        <CampoDeTextoComMascara
          label='Telefone para recado'
          name='telefoneParaRecado'
          mascara={mascaraDeTelefoneParaRecado}
          tipo='tel'
          required={false}
          value={dadosDoPretendente?.contato?.telefoneParaRecado || ''}
          disabled={disabled}
          {...inputEvents}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeContato({...dadosDoPretendente.contato, telefoneParaRecado: event.target.value})}
        />
        <CampoDeTexto
          label='Nome do responsável'
          name='nomeDoResponsavel'
          tipo='text'
          required={!!dadosDoPretendente?.contato?.telefoneParaRecado}
          value={dadosDoPretendente?.contato?.nomeDoResponsavel || ''}
          disabled={disabled || !dadosDoPretendente?.contato?.telefoneParaRecado}
          erro={erros?.nomeDoResponsavel}
          {...inputEvents}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeContato({...dadosDoPretendente.contato, nomeDoResponsavel: event.target.value})} />
      </div>
    </>
  );
};

export default CampoContato;