import React, { useEffect, useState } from 'react';
//@ts-ignore
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { IPretendenteValidacaoErroCampos } from '../../interfaces/IPretendenteValidacao';
import IPretendente from '../../interfaces/IPretendente';
import IEstado from '../../interfaces/IEstado';
import ICidade from '../../interfaces/ICidade';
import initialState from '../../reducers/initialState.reducer';
import SelecaoDeEstado from '../SelecaoDeEstado';
import { carregarCidadesDoEstado } from '../../servicos/cidades';
import { adicionarCidades } from '../../actions/cidadesDoBrasil.actions';
import { mapearCidadesParaSelect } from '../../selectors';
import MensagemDeErro from '../MensagemDeErro';

interface ICamposNacionalidadeProps {
  dadosPretendente: IPretendente,
  erros?: IPretendenteValidacaoErroCampos,
  disabled: boolean,
  onChangeCidadeDeNaturalidade: (cidade: ICidade) => void,
}

const CamposDeNaturalidade = ({ dadosPretendente, onChangeCidadeDeNaturalidade, erros, disabled }: ICamposNacionalidadeProps) => {

  const dispatch = useDispatch();
  const habilitarCampoDeCidade = dadosPretendente.cidadeDeNaturalidade?.estado?.uf;
  const cidadesDoBrasil = useSelector((state: typeof initialState) => state.cidadesDoBrasil) as ICidade[];
  const [cidadesDoEstado, setCidadesDoEstado] = useState<ICidade[]>([]);

  useEffect(() => {
    if (dadosPretendente.cidadeDeNaturalidade?.estado?.uf)
      atualizarCidadesDoEstado(dadosPretendente.cidadeDeNaturalidade?.estado?.uf);
  }, []);

  const handleOnChangeEstado = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value) {
      onChangeCidadeDeNaturalidade({ id: 0, cepPadrao: '', nome: '', estado: { uf: value } as IEstado} as ICidade);
      atualizarCidadesDoEstado(value);
    }
    else
      onChangeCidadeDeNaturalidade({} as ICidade);
  };

  const atualizarCidadesDoEstado = async (uf: string) => {
    const cidadesDoEstadoSelecionado = cidadesDoBrasil?.filter(item => item?.estado?.uf === uf);
    if (cidadesDoEstadoSelecionado.length === 0) {
      const cidades = await buscarCidadesDoEstado(uf);
      setCidadesDoEstado(cidades);
    }else
      setCidadesDoEstado(cidadesDoEstadoSelecionado);
  };

  const buscarCidadesDoEstado = async (uf: string): Promise<ICidade[]> => {
    const cidades = await carregarCidadesDoEstado(uf);
    dispatch(adicionarCidades(cidades));
    return cidades;
  };

  const handleOnChangeCidade = (selected: { label: string, value: number }) => {
    const idDaCidade = selected?.value;
    if (idDaCidade){
      const cidade = idDaCidade ? cidadesDoBrasil.find(cidade => cidade?.id == idDaCidade) : {};
      onChangeCidadeDeNaturalidade(cidade as ICidade);
    }else{
      onChangeCidadeDeNaturalidade({ id: 0, nome:'', cepPadrao:'', estado: dadosPretendente.cidadeDeNaturalidade?.estado as IEstado});
    }
  };  
  
  return (
    <>
      <div className="grade__linha grade__linha_alinhar-vertical-a-base">
        <SelecaoDeEstado
          uf={dadosPretendente?.cidadeDeNaturalidade?.estado?.uf || ''}
          label='Estado'
          clearable={true}
          onChange={handleOnChangeEstado}
          disabled={disabled}
        />
        <div className="grade__coluna grade__coluna_6">
          <div className={`formulario__input-react-select ${erros?.cidadeDeNaturalidade && 'formulario__input-react-select_erro'}`}>
            <label className="formulario__label">
              Cidade
            </label>
            {erros?.cidadeDeNaturalidade && <MensagemDeErro campo='cidade' />}
            <Select
              name="cidade"
              id="cidade"
              placeholder="Selecione a cidade"
              value={dadosPretendente?.cidadeDeNaturalidade?.id || ''}
              options={mapearCidadesParaSelect(cidadesDoEstado)}
              onChange={handleOnChangeCidade}
              disabled={!habilitarCampoDeCidade || disabled}
              clearable={true} />
          </div>
        </div>
      </div>
    </>);
};

export default CamposDeNaturalidade;