import { apiDaInscricao } from './axios';

const consultarInscricao = async (inscricaoId: Number) => {
  return await apiDaInscricao().get(`/menudeinscricao/${inscricaoId}`);
};

const consultarInformacoesDoMenu = async (inscricaoId: Number) => {
  return await apiDaInscricao().get(`/menu/${inscricaoId}/informacoes`);
};

const obterDadosGeraisDaInscricao = async (inscricaoId: Number) => {  
  return await apiDaInscricao().get(`/menudeinscricao/${inscricaoId}/dadosGerais`);
};

export { consultarInscricao, consultarInformacoesDoMenu, obterDadosGeraisDaInscricao };