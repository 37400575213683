import React, { useEffect, useState } from 'react';
import BarraDeNavegacao from '../../componentes/BarraDeNavegacao';
import Container from '../../componentes/Container';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import IPretendente from '../../interfaces/IPretendente';
//@ts-ignorets
import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';
import { consultarInscricao } from '../../servicos/MenuDeInscricao';
import IContato from '../../interfaces/IContato';
import Section from '../../componentes/Section';
import { CampoContato } from '../../componentes/FormularioPretendente/componentes';
import { IPretendenteValidacaoErroCampos } from '../../interfaces/IPretendenteValidacao';
import validacao from './validacao';
import { editarInformacoesDeContato } from '../../servicos/informacoesDeContato';

const InformacoesDeContato = () => {

  const idDaInscricao = parseInt(localStorage.getItem('idDaInscricao') || '0');
  const temPermissaoParaEditarInformacoesDeContato = localStorage.getItem('temPermissaoParaEditarInformacoesDeContato');
  const [dadosDoPretendente, setDadosDoPretendente] = useState<IPretendente>({} as IPretendente);
  const [erros, setErros] = useState<IPretendenteValidacaoErroCampos | undefined>();
  const dispatch = useDispatch();

  useEffect(() => {
    obterInformacoesDaInscricao(idDaInscricao);
  }, [idDaInscricao]);

  const obterInformacoesDaInscricao = async (idDaInscricao: number) => {
    if (idDaInscricao) {
      const resposta = await consultarInscricao(idDaInscricao);
      const dadosDotitular = resposta.data.titular;
      setDadosDoPretendente(dadosDotitular);
    }else
      voltarTelaDeListagemDeInscricoes();
  };

  const validarPermissaoParaEditarContato = () =>  {
    if (temPermissaoParaEditarInformacoesDeContato != 'true'){
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Separação de inscrição:',
        texto: 'Permissão negada para executar esta ação.'
      });
      voltarTelaDeListagemDeInscricoes();
    }
  };

  const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    removerErro(event.target.name);
  };
  
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    removerErro(event.target.name);
  };
  
  const onFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    removerErro(event.target.name);
  };

  const onChangeContato = (contato: IContato) => {
    if (!contato.telefoneParaRecado)
      contato.nomeDoResponsavel = '';

    setDadosDoPretendente({ ...dadosDoPretendente, contato });
  };

  const removerErro = (...campos: string[]): void => {
    if (campos == undefined || erros == undefined) return;

    //@ts-ignore
    campos.forEach((campo: string) => erros[campo] = undefined);
    setErros(erros);
  };

  const validarFormulario = () => {
    const errosValidacao = validacao.validarFormulario(dadosDoPretendente.contato);
    setErros(errosValidacao);
    return !Object.keys(errosValidacao).length;
  };

  const salvarDadosDoContato = async () => {

    if (!validarFormulario()) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Formulário inválido. Por favor corrija os campos obrigatórios.'
      });
      return;
    }
    await editar();
  };

  const editar = async () => {
    await editarInformacoesDeContato(idDaInscricao, dadosDoPretendente.contato);
    GerenciadorDeMensagem.criarMensagem({
      tipo: 'sucesso',
      icone: 'far fa-check-circle',
      titulo: 'Tudo certo!',
      texto: 'Dados de contato atualizados com sucesso.'
    });
    voltarTelaDeListagemDeInscricoes();
  };

  const inputEvents = { onChange, onBlur, onFocus };

  const voltarTelaDeListagemDeInscricoes = () => {
    localStorage.removeItem('idDaInscricao');
    localStorage.removeItem('temPermissaoParaEditarInformacoesDeContato');
    dispatch(push('/listagem-de-inscricoes'));
  };

  validarPermissaoParaEditarContato();

  return (
    <>
      <div className='container-separacao-inscricao'>
        <BarraDeNavegacao
          titulo="Editar informações de contato"
          comBotaoVoltar={true}
          acaoBotaoVoltar={() => dispatch(push('/listagem-de-inscricoes'))}
          comBotaoADireita={false}
          iconeBotaoADireita={false}
          acaoBotaoADireita={() => dispatch(push('/menu-inscricao'))}
        />

        <Container tamanhoDoContainer="pequeno" comCabecalhoFixo={true}>
          <Section>
            {Object.keys(dadosDoPretendente).length > 0 && 
            <>
              <CampoContato
                dadosDoPretendente={dadosDoPretendente}
                erros={erros}
                inputEvents={inputEvents}
                disabled={false}
                onChangeContato={onChangeContato}
                mostrarIndicadorDePasso={true}
              />
              <hr />
              <div className="grade__coluna">
                <button
                  className="botao botao_medio botao_cor-secundaria botao_largura-total"
                  onClick={salvarDadosDoContato}>
                  Salvar
                </button>
              </div>
            </>
            }
          </Section>
        </Container>
      </div>
    </>
  );
};

export default InformacoesDeContato;