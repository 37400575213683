import IDependente from '../interfaces/IDependente';
import IPretendente from '../interfaces/IPretendente';

const inscricaoPossuiFilhoMenorDeIdade = (dependentes:IDependente[]) => {
  const grauDeParentescoAceitos = ['Filho', 'Enteado'];

  let filhosMenoresDeIdade = dependentes.filter((dependente:IDependente) =>
    obterIdade(dependente.dataDeNascimento) < 18
        && (grauDeParentescoAceitos.includes(dependente.grauDeParentesco)));

  return filhosMenoresDeIdade.length > 0;
};

const obterIdade = (dataDeNascimento?:Date) => {

  if (!dataDeNascimento)
    return 0;

  var camposDaData = dataDeNascimento.toString().split('/');
  var anoDeNascimento = parseFloat(camposDaData[2]);
  var mesDeNascimento = parseFloat(camposDaData[1]);
  var diaDeNascimento = parseFloat(camposDaData[0]);

  var dataAtual = new Date(),
    anoAtual = dataAtual.getFullYear(),
    mesAtual = dataAtual.getMonth() + 1,
    diaAtual = dataAtual.getDate();
        
  anoDeNascimento = +anoDeNascimento;
  mesDeNascimento = +mesDeNascimento;
  diaDeNascimento = +diaDeNascimento;

  var totalDeAnos = anoAtual - anoDeNascimento;

  if (mesAtual < mesDeNascimento || (mesAtual == mesDeNascimento && diaAtual < diaDeNascimento)) {
    totalDeAnos = anoAtual - anoDeNascimento;
    totalDeAnos--;
  }

  return totalDeAnos < 0 ? 0 : totalDeAnos;
};

const inscricaoPossuiIdoso = (naoTemDadosDoConjuge:boolean, dadosDoPretendente:IPretendente, dadosDoConjuge:IPretendente, dependentes:IDependente[]) => {
  let conjugeEhIdoso = naoTemDadosDoConjuge ? false : dadosDoConjuge.ehIdoso;
  let existeAlgumDependenteIdoso = dependentes.filter((dependente:IDependente) => dependente.ehIdoso).length > 0;

  return conjugeEhIdoso || dadosDoPretendente.ehIdoso || existeAlgumDependenteIdoso;
};

const inscricaoPossuiPessoaComDeficiencia = (naoTemDadosDoConjuge:boolean, dadosDoPretendente:IPretendente, dadosDoConjuge:IPretendente, dependentes:IDependente[]) => {
  let existeAlgumDependenteComDeficiencia = dependentes.filter(dependente => dependente.deficiencias).length > 0;
  let conjugePossuiDeficiencia = naoTemDadosDoConjuge ? false : dadosDoConjuge.deficiencias;
  return dadosDoPretendente.deficiencias || conjugePossuiDeficiencia || existeAlgumDependenteComDeficiencia;
};

const inscricaoPossuiPessoaComDoencaCronica = (naoTemDadosDoConjuge:boolean, dadosDoPretendente:IPretendente, dadosDoConjuge:IPretendente, dependentes:IDependente[]) => {
  let existeAlgumDependenteComDoencaCronica = dependentes.filter((dependente:IDependente) => dependente.cidDaDoencaCronica).length > 0;
  let conjugePossuiDoencaCronica = naoTemDadosDoConjuge ? false : (dadosDoConjuge.cidDaDoencaCronicaIncapacitante || dadosDoConjuge.cidDaDoencaCronicaDegenerativa || dadosDoConjuge.cidDaDoencaCronicaCancer);
  let pretendentePossuiDoencaCronica = (dadosDoPretendente.cidDaDoencaCronicaIncapacitante || dadosDoPretendente.cidDaDoencaCronicaDegenerativa || dadosDoPretendente.cidDaDoencaCronicaCancer);
  return pretendentePossuiDoencaCronica || conjugePossuiDoencaCronica || existeAlgumDependenteComDoencaCronica;
};

const inscricaoPossuiMulherChefeDeFamilia = (dadosDoPretendente:IPretendente, naoTemDadosDoConjuge:boolean, dadosDoConjuge:IPretendente) => {
  return dadosDoPretendente.mulherChefeDeFamilia == true ||
        (!naoTemDadosDoConjuge && dadosDoConjuge.mulherChefeDeFamilia == true);
};

const inscricaoPossuiMulherVitimaDeViolenciaDomestica = (dadosDoPretendente: IPretendente, naoTemDadosDoConjuge: boolean, dadosDoConjuge: IPretendente) => {
  return dadosDoPretendente.ehMulherVitimaDeViolenciaDomestica === true ||
        (!naoTemDadosDoConjuge && dadosDoConjuge.ehMulherVitimaDeViolenciaDomestica === true);
};

export default {
  obterIdade, inscricaoPossuiFilhoMenorDeIdade, inscricaoPossuiIdoso, inscricaoPossuiPessoaComDeficiencia,
  inscricaoPossuiPessoaComDoencaCronica, inscricaoPossuiMulherChefeDeFamilia, inscricaoPossuiMulherVitimaDeViolenciaDomestica
};