import { manterSomenteNumeros } from '../../helpers/mascaras';
import IContato from '../../interfaces/IContato';
import IContatoValidacaoCampos, { IContatoValidacaoErroCampos } from '../../interfaces/IContatoValidacao';

let _dadosDeContato: IContato;

const nome = (nome: string) => {
  const regex = /^[a-záàâãéèêíïóôõöúçñ ]+$/i;
  if (!!nome && !regex.test(nome))
    return 'Não é permitido a entrada de números ou caracteres especiais';
};

function telefone() {
  if (_dadosDeContato == undefined)
    return 'É obrigatório o preenchimento de ao menos um telefone';

  const { telefoneCelular, telefoneParaRecado } = _dadosDeContato;
  if (!telefoneCelular && !telefoneParaRecado)
    return 'É obrigatório o preenchimento de ao menos um telefone';
}

function telefoneCelular() {
  const telefoneCelular = _dadosDeContato?.telefoneCelular;
  if (!!telefoneCelular && manterSomenteNumeros(telefoneCelular).length != 11)
    return 'O telefone celular deve conter 11 dígitos';
}

function nomeDoResponsavel() {
  if (_dadosDeContato != undefined) {
    const { nomeDoResponsavel, telefoneParaRecado } = _dadosDeContato;
    if (telefoneParaRecado && !nomeDoResponsavel)
      return 'O nome do responsável é obrigatório';
  }
}

function telefoneParaRecado() {
  const telefoneParaRecado = _dadosDeContato?.telefoneParaRecado;
  if (!!telefoneParaRecado && manterSomenteNumeros(telefoneParaRecado).length < 10)
    return 'O telefone para recado deve conter pelo menos 10 dígitos';
}

const email = (email: string) => {
  const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
  if (email && !regex.test(email))
    return 'Email inválido';
};

const validacoes: IContatoValidacaoCampos = {
  telefone: [telefone, telefoneCelular, telefoneParaRecado],
  email: [email],
  nomeDoResponsavel: [nome, nomeDoResponsavel],
};

const aplicarFuncoesDeValidacao = (contexto: IContato, campo: string, valor: string) => {
  //@ts-ignore
  const funcoes = validacoes[campo] || [];
  _dadosDeContato = contexto;
  const retornosComErro = funcoes.map((f: Function) => f.call(contexto, valor)).filter((r: any) => !!r);

  return retornosComErro[0];
};

const validarFormulario = (dadosDoContato: IContato): IContatoValidacaoErroCampos => {

  const errosValidacao: any = Object.keys(validacoes).reduce((erros, campo) => {
    //@ts-ignore
    const erro = aplicarFuncoesDeValidacao(dadosDoContato, campo, dadosDoContato[campo]);
    if (erro)
      return { ...erros, [campo]: erro };
    return erros;
  }, {});
  return errosValidacao;
};
export default { validarCampo: aplicarFuncoesDeValidacao, validarFormulario };