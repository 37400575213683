import React, { Component } from 'react';
import { Route } from 'react-router';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import {
  Login, AvisoDocumentos,
  PaginaDeEntrada, CadastroDeInscricao, RedefinirSenha, 
  DefinirSenha,  ConfirmacaoDeCodigo, EnvioDeCodigo, 
  LocaisDeAtendimento,
  SeparacaoDeInscricao, DadosTitular,
  ListagemDeInscricoes
} from '../';
import { Loading, ScrollParaOTopo } from '../../componentes';
import { RotaPublicaAutenticada } from '../../servicos/rotaPublicaAutenticada';
import { RotaAdministrativaAutenticada } from '../../servicos/rotaAdministrativaAutenticada';
import { RotaDinamica } from '../../servicos/rotaDinamica';
import configureStore from '../../store';
import '../../estilos/estilos.scss';
import { efetuarLoginPorCpf } from '../../actions/login.actions';
import { obterDadosAutenticacao, estaAutenticado, validarAcessoPublico, validarAcessoAdministrativo } from '../../servicos/autenticacao';
import { configureAxios } from '../../servicos/axios';
import { cargaInicial } from '../../actions/cargaInicial.actions';
import ContainerDeMensagensFlutuantes from '@bit/digix.digixui.mensagens-flutuantes';
import * as Sentry from '@sentry/react';
import InformacoesDeContato from '../InformacoesDeContato';
export const history = createBrowserHistory({ basename: process.env.REACT_APP_BASENAME });
const store = configureStore(history);
configureAxios(store);

store.dispatch(cargaInicial());

if (estaAutenticado()) {
  const dadosDoLocalStorage = obterDadosAutenticacao();
  store.dispatch(efetuarLoginPorCpf(dadosDoLocalStorage));
}

if (validarAcessoPublico()){
  const dadosDoLocalStorage = obterDadosAutenticacao();
  const scope = Sentry.getCurrentScope();
  scope.setUser({
    'Cidade': dadosDoLocalStorage.cidade,
    'Cpf': dadosDoLocalStorage.cpf,
    'Nome': dadosDoLocalStorage.nome,
    'NumeroDaInscricao': dadosDoLocalStorage.numeroDaInscricao,
    'DataDeNascimento': dadosDoLocalStorage.dataDeNascimento,
    'IdDaInscricao': dadosDoLocalStorage.idDaInscricao
  });

  window.cpf = dadosDoLocalStorage.cpf;
  window.nome = dadosDoLocalStorage.nome;
} else if (validarAcessoAdministrativo()){
  const scope = Sentry.getCurrentScope();
  scope.setUser({
    'LoginDoUsuario': localStorage.getItem('loginDoUsuario'),
    'LocalDeAtendimento': localStorage.getItem('localDeAtendimento')
  });
}

export default class Layout extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div>
            <ScrollParaOTopo />
            <ContainerDeMensagensFlutuantes />
            <Route exact path='/' component={PaginaDeEntrada} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/cadastro' component={CadastroDeInscricao} />
            <Route exact path='/redefinir-senha' component={RedefinirSenha} />
            <Route exact path='/redefinir-senha/envio-de-codigo' component={EnvioDeCodigo} />
            <Route exact path='/redefinir-senha/confirmar-codigo' component={ConfirmacaoDeCodigo} />
            <Route exact path='/definir-senha' component={DefinirSenha} />
            <RotaPublicaAutenticada path='/aviso-documentos' component={AvisoDocumentos} />
            <RotaDinamica />
            <RotaAdministrativaAutenticada path='/locais-de-atendimento' component={LocaisDeAtendimento} />
            <RotaAdministrativaAutenticada path='/listagem-de-inscricoes' component={ListagemDeInscricoes} />
            <RotaAdministrativaAutenticada path='/separacao-de-inscricao' component={SeparacaoDeInscricao} />
            <RotaAdministrativaAutenticada path='/nova-inscricao' component={DadosTitular} />
            <RotaAdministrativaAutenticada path='/informacoes-de-contato' component={InformacoesDeContato} />
            <Loading />
          </div>
        </ConnectedRouter>
      </Provider>
    );
  }
}