import React from 'react';
import { apiDaInscricaoSemLoading } from '../../../../servicos/axios';
import CampoRadioButton from '../../../CampoRadioButton';
import CampoDeSelecaoAssincrono from '../../../CampoDeSelecaoAssincrono';
import MensagemDeErro from '../../../MensagemDeErro';
import { IPretendenteValidacaoErroCampos } from '../../../../interfaces/IPretendenteValidacao';

interface IPaisProps {
  sigla: string,
  nome: string
}

interface ICampoEstrangeiroProps {
  ehEstrangeiro: boolean,
  pais: string,
  erros?: IPretendenteValidacaoErroCampos,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  atualizarPais: (sigla?: string, nome?: string) => void,
  disabled: boolean
}

const CampoEstrangeiro = ({ ehEstrangeiro, pais,
  erros, onChange, atualizarPais, disabled }: ICampoEstrangeiroProps) => {

  async function obterPaises() {
    const { data } = await apiDaInscricaoSemLoading().get('/pais');
    return {
      options: data.map((pais: IPaisProps) => {
        return { value: pais.sigla, label: pais.nome };
      })
    };
  }

  const OnChangePais = (e: { label: string, value: string }) => {
    if (e)
      atualizarPais(e.value, e.label);
    else
      atualizarPais(undefined, undefined);
  };

  return (
    <div className="grade__coluna">
      <fieldset className="formulario__grupo-de-inputs">
        <legend className="formulario__label">
          É estrangeiro(a)?
          <p className="formulario__descricao">
            Pessoa de origem estrangeira que não possui a nacionalidade brasileira.
          </p>
        </legend>
        {erros?.ehEstrangeiro && <MensagemDeErro />}
        <CampoRadioButton
          mesmaLinha
          name="ehEstrangeiro"
          value="true"
          label="Sim"
          checked={ehEstrangeiro.toString()}
          onChange={onChange}
          disabled={disabled} />
        <CampoRadioButton
          mesmaLinha
          name="ehEstrangeiro"
          value="false"
          label="Não"
          checked={ehEstrangeiro.toString()}
          onChange={onChange}
          disabled={disabled}/>
      </fieldset>
      {
        ehEstrangeiro &&
        <div className="grade__coluna">
          <div className={`formulario__input-react-select ${erros?.pais && 'formulario__input-react-select_erro'}`}>
            <label className="formulario__label">
              País de origem
            </label>
            {erros?.pais &&
              <MensagemDeErro />}
            <CampoDeSelecaoAssincrono
              placeholder={'Selecione o país'}
              value={pais}
              disabled={disabled}
              textoDeBusca={'Digite o nome para buscar'}
              obterOpcoes={obterPaises}
              onChange={(e: { label: string, value: string }) => OnChangePais(e)}
            />
            <hr />
          </div>
        </div>
      }
    </div>);

};

export default CampoEstrangeiro;